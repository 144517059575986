<template>
  <router-layout>
    <div class="page hevh">
			<van-loading
				color="#1989fa" 
				type="spinner" 
				class="Loading" 
				v-if="loading" 
			/>
       <div class="dataBox" >
				 <div class="map" id="map1"></div>
				 <div class="mapnNearby" id="panelshow"></div>
			 </div>
    </div>
  </router-layout>
</template>

<script>
import authMixin from "@/mixin/mixin";
  export default {
    data(){
      return {
				loading:true,
				nearBy:'',
        point:[],
      }
		},
		mixins: [authMixin],
		activated(){
			this.nearBy = this.$route.query.nearBy
			this.point = JSON.parse(this.$route.query.point)
			this.createMap()
		},
    methods:{
      createMap(){
        this.map = new AMap.Map('map1', {
          resizeEnable: true,
          center: this.point,//经纬度
          zoom: 13
        })
				AMap.service(["AMap.PlaceSearch"], ()=>{
					this.placeSearch = new AMap.PlaceSearch({
						pageSize: 100,
						pageIndex: 1, 
						map: this.map, 
						panel: "panelshow", 
						autoFitView: true 
					})
				})
        this.placeSearch.searchNearBy(this.nearBy, this.point, 500, (status, res)=>{
					if(status === 'complete' || status === 'no_data'){
						this.loading = false
					}
				})
      }
    },
		
  }
</script>

<style  lang="scss">
  @import '../../../assets/iconfont/iconfont.css';
  .hevh{
    height: 100vh;
    overflow: hidden;
  }
	.dataBox{
		height: 100vh;
		overflow: hidden;	
	}
  .map{
    height: 60%;
    width: 100%;
    overflow: hidden;
		position: relative;
		z-index: 1;
  }
  .mapnNearby{
		box-shadow: 0px -3px 20px rgba(0,0,0, 0.12);
		height:40%;
		width: 100%;
		overflow-y: auto;
		position: relative;
		z-index: 2;
		.amap_lib_placeSearch_page{
			background: #fff !important;
		}
		.amap_lib_placeSearch{
			border: none !important;
		}
	}
	.Loading{
		position: fixed;
		left:50%;
		top:50%;
		transform: translate(-50%,-50%);
		z-index: 2;
	}
</style>